import Head from 'next/head';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import {
  Logo,
  IconBulb,
  IconDashboard,
  IconFilter,
} from '@phpcreation/frontend-components-react';

const arrayNav = [
  {
    title: 'config Prototype',
    desc: 'Access listing and manage configs like tenants, hostnames...',
    link: '/login',
    icon: <IconDashboard className="w-10 h-10 text-blue-500" />,
  },
  {
    title: 'Back-end status',
    desc: 'Access status of the API Endpoints, generate default tokens.',
    link: '/status-api/view',
    icon: <IconFilter className="w-10 h-10 text-green-500" />,
  },
  {
    title: 'Documentation',
    desc: 'Dive into the full documentation from componenets, tech stack , tests ..',
    link: 'https://dev.react-doc.phpr.link/',
    icon: <IconBulb className="w-10 h-10 text-yellow-500" />,
  },
];

export default function Home() {
  return (
    <div
      className="flex flex-col min-h-screen bg-grayish"
      style={{ fontFamily: 'Roboto' }}
    >
      <Head>
        <title>PHPReaction | prototype</title>
        <meta name="description" content="phpreaction prototype" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="bg-white rounded-xl pt-2 px-2 scale-75  shadow m-2 w-40">
        <Logo />
      </div>
      <main className="z-10 flex  flex-col items-center  p-5 lg:p-24  ">
        <h2 className="tv:text-8xl hd-desktop:text-4xl desktop:text-3xl text-2xl  font-bold  text-gray-700  ">
          Welcome to PHPReaction 👋
        </h2>
        <p className="text-gray-500 tv:text-3xl hd-desktop:text-lg desktop:text-base text-xs">
          {' '}
          Where would you like to go?
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 md:p-12 xl:gap-24">
          {arrayNav.map(item => (
            <Link key={arrayNav.indexOf(item)} href={item.link}>
              <div className="__Card flex flex-col  items-center  justify-between p-2 w-auto tv:h-96 h-64 bg-white  tv:rounded-3xl rounded-xl   border border-gray-300 shadow-sm hover:border-blue-400 cursor-pointer transform hover:scale-[1.02] transition-all ease-linear ">
                <div className="h-20  w-full flex items-center justify-center  ">
                  {item.icon}
                </div>
                <h2 className="tv:text-5xl hd-desktop:text-xl desktop:text-base text-sm  capitalize">
                  {item.title}{' '}
                </h2>
                <p className="w-full  text-center text-gray-600 tv:text-3xl hd-desktop:text-lg desktop:text-base  text-xs">
                  {item.desc}
                </p>
                <Link href={item.link}>
                  <a className="flex justify-center items-center  no-underline w-40  p-1 px-4 text-gray-500 tv:text-3xl hd-desktop:text-lg desktop:text-base  text-xs">
                    Visit
                  </a>
                </Link>
              </div>
            </Link>
          ))}
        </div>
      </main>

      <footer className="static lg:absolute z-10 bottom-0 bg-grayish p-2 flex justify-center items-center border w-full text-gray-400 ">
        Powered by
        <span className="text-gray-600 font-bold px-1"> PHPCreation Inc.</span>
      </footer>
    </div>
  );
}
